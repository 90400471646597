import React, {useContext, useEffect, useState} from 'react';
import { Helmet } from 'rnd-helmet';
import {injectIntl} from "react-intl";
import { useDataLayer } from "dataLayerHelper";
import SubMenu from "@Components/SubMenu";
import axios from "axios";
import FormContext from "formContext";
import Form from 'form';

function ResendConfirmation({intl, staticContext}) {
  const { locale } = intl;
  const [ verificationSent, setVerificationSent ] = useState(false);
  const [ verificationSettings, setVerificationSettings ] = useState({
    text: 'ResendConfirmation.Notice.Text',
    type: 'warning'
  });
  const {formData, currentStep, handleChange, setLoading} = useContext(FormContext);

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext, "registration");

  const resendVerification = (event) => {
    event.preventDefault();
    setVerificationSent(true);
    setLoading(true);

    try {
      axios.post(`${process.env.REACT_APP_API_PREFIX}/resend-confirmation/`, {email: formData[currentStep]['email'] })
        .then(response => {
          if (response.status === 200) {
            // Display the popup from above.
            setVerificationSettings({
                message: response.data.message,
                type: 'informative'
              }
            );
            setLoading(false);
          }
        });
    }
    catch (e) {
      setVerificationSettings({
        message: 'Something wen\'t wrong.',
        type: 'negative'
        }
      );
      setLoading(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Breadcrumb.RegisterConfirmation'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className="header header--text bg-variant-brand-primary"/>
      </Helmet>
      <div className="header header--text bg-variant-brand-primary">
        <div className="header__wrapper wrapper">
          <SubMenu />
          <div className="header__content header__content--l content-block has-breadcrumbs">
            <h1 className="content-block__title">
              <span className="content-block__title-top">{intl.formatMessage({id: 'Login.Header.Verification.Title'})}</span>
            </h1>
          </div>
          <div className="header__media media-block">
            <img src="/myrandstad-app/assets/image/icons/corona-illustration.svg" alt="" />
          </div>
        </div>
      </div>
      <Form name='resend-confirmation' handleChange={handleChange} state={formData[currentStep]} handleSubmit={resendVerification} noticeSettings={verificationSettings} divClasses='block bg-variant-white block--s' />
    </>
  )
}

export default injectIntl(ResendConfirmation);
